import './BottomPopup.less'

import { Popover, notification } from 'antd'
import { navigate } from 'gatsby-plugin-react-intl'
import React, { useContext, useEffect, useState } from 'react'

import { useScopedIntl } from '../../../hooks'
import { AccountType, AppVersion, fetchApi, fetchGeneralInfo } from '../../../requests'
import { routes } from '../../../routes'
import { getInitials } from '../../../utils'
import { UserContext } from '../../auth'
import { DatacIcon, DatacMessage } from '../../common'

interface Props {
  blockNavigation?: boolean
}

export const BottomPopup: React.VFC<Props> = ({ blockNavigation }) => {
  const intl = useScopedIntl('')
  const { user } = useContext(UserContext)
  const [appVersion, setAppVersion] = useState<AppVersion>()

  useEffect(() => {
    fetchGeneralInfo({
      onSuccess: (_, appVersion) => setAppVersion(appVersion),
      onRequestError: code => DatacMessage.genericError(intl, code)
    })
  }, [])

  const onLogout = () => {
    if (blockNavigation) {
      DatacMessage.warning(intl('action_blocked.title'), intl('action_blocked.description'))
      return
    }

    notification.destroy()
    fetchApi.signOut({
      onSuccess: () => navigate(routes.signIn(AccountType.User)),
      onRequestError: () => DatacMessage.genericError(intl)
    })
  }

  const onHelp = () => window.open('https://datacaptsupport.zendesk.com/hc/en-001', '_blank')

  return (
    <Popover
      placement="leftBottom"
      trigger="click"
      content={
        <div className="basic-layout-bottom-popup">
          <div className="basic-layout-bottom-popup__initials basic-layout-bottom-popup__initials__top">
            {getInitials(user.name)}
          </div>
          <div className="basic-layout-bottom-popup__name">{intl('menu.hi', { name: user.name })}</div>
          <div className="basic-layout-bottom-popup__email">{user.email}</div>
          <div className="basic-layout-bottom-popup__option" onClick={onHelp}>
            <DatacIcon raw name="lifeBuoy" /> {intl('menu.helpdesk')}
          </div>
          <div className="basic-layout-bottom-popup__option" onClick={onLogout}>
            <DatacIcon raw name="logOut" /> {intl('menu.logout')}
          </div>
          <div className="basic-layout-bottom-popup__version">
            Datacapt™ version {appVersion?.code}
            <br />
            {appVersion?.date}
          </div>
        </div>
      }
    >
      <div className="basic-layout-bottom-popup__initials basic-layout-bottom-popup__initials__bottom">
        {getInitials(user.name)}
      </div>
    </Popover>
  )
}
