import './SettingsApiKeyRegenerateModal.less'

import React from 'react'

import { useScopedIntl } from '../../../../hooks'
import { DatacModal } from '../../../common'

interface Props {
  isOpened: boolean
  onClose: () => void
  onRegenerate: () => void
}

export const SettingsApiKeyRegenerateModal: React.FC<Props> = ({ isOpened, onClose, onRegenerate }) => {
  const intlRegenerate = useScopedIntl('settings.api_key.regenerate')

  const onSubmit = () => {
    onRegenerate()
    onClose()
  }

  return (
    <DatacModal
      isOpened={isOpened}
      onClose={onClose}
      title={intlRegenerate('title')}
      submitLabel={intlRegenerate('submit')}
      onSubmit={onSubmit}
      destroyOnClose
    >
      <div className="settings-settings-api-key-regenerate-modal__body">{intlRegenerate('description')}</div>
    </DatacModal>
  )
}
