import './SettingsLayout.less'

import React, { useContext, useEffect } from 'react'

import { useScopedIntl } from '../../../hooks'
import { AclAction, AclFeature, LoginType } from '../../../requests'
import { routes } from '../../../routes'
import { UserContext } from '../../auth'
import { DatacMenuOption, DatacMessage, DatacTitle } from '../../common'

export const SettingsLayout: React.FC = ({ children }) => {
  const intl = useScopedIntl('')
  const intlMenu = useScopedIntl('settings.menu')
  const { user } = useContext(UserContext)

  useEffect(() => {
    if (user.shouldShowNextReleaseInfo()) {
      DatacMessage.nextReleaseInfo(intl, user.getNextRelease(), user.closeNextReleaseNotes, user.language)
    }
  }, [user])

  const areComplianceSettingsEnabled =
    user.canDo(AclFeature.SubjectConsents)(AclAction.Edit) ||
    (user.canDo(AclFeature.GeneralSettings)(AclAction.Edit) && user.isSubjectRepositoryEnabled)

  return (
    <div className="settings-layout">
      <div className="settings-layout__menu">
        <DatacTitle type="h1" className="settings-layout__menu-title">
          {intlMenu('title')}
        </DatacTitle>
        <DatacMenuOption
          route={routes.settingsPersonalDetails}
          label={intlMenu('personal_details')}
          iconName="user"
          exactMatch
        />
        {user.loginType === LoginType.Password && (
          <DatacMenuOption route={routes.settingsPasswordChange} label={intlMenu('password')} iconName="lock" />
        )}
        {user.canAccess(AclFeature.GlobalUsersManagement) && (
          <DatacMenuOption route={routes.settingsUsers} label={intlMenu('users')} iconName="userPlus" />
        )}
        {user.canDo(AclFeature.Roles)(AclAction.Edit) && (
          <DatacMenuOption route={routes.settingsRoles} label={intlMenu('roles')} iconName="lock" />
        )}
        {user.canAccess(AclFeature.Centers) && (
          <DatacMenuOption route={routes.settingsCenters} label={intlMenu('centers')} iconName="disc" />
        )}
        {user.canAccess(AclFeature.Templates) && (
          <DatacMenuOption route={routes.settingsTemplates} label={intlMenu('templates')} iconName="fileText" />
        )}
        {user.canAccess(AclFeature.AuditTrails) && (
          <DatacMenuOption route={routes.settingsAuditTrails} label={intlMenu('audit_trails')} iconName="clipboard" />
        )}
        {areComplianceSettingsEnabled && (
          <DatacMenuOption route={routes.settingsCompliance} label={intlMenu('compliance')} iconName="checkSquare" />
        )}
        {user.isSubjectRepositoryEnabled && user.canAccess(AclFeature.Translations, true) && (
          <DatacMenuOption route={routes.settingsTranslations} label={intlMenu('translations')} iconName="globe" />
        )}
        {user.canDo(AclFeature.Customisation)(AclAction.Edit) && (
          <DatacMenuOption route={routes.settingsCustomisation} label={intlMenu('customisation')} iconName="edit" />
        )}
        {user.canAccess(AclFeature.ApiKey) && user.isApiEnabled && (
          <DatacMenuOption route={routes.settingsApiKey} label={intlMenu('api_key')} iconName="key" />
        )}
      </div>
      <main className="settings-layout__content">{children}</main>
    </div>
  )
}
