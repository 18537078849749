import './SettingsApiKeyDeleteModal.less'

import React from 'react'

import { useScopedIntl } from '../../../../hooks'
import { DatacModal } from '../../../common'

interface Props {
  isOpened: boolean
  onClose: () => void
  onDelete: () => void
}

export const SettingsApiKeyDeleteModal: React.FC<Props> = ({ isOpened, onClose, onDelete }) => {
  const intlDelete = useScopedIntl('settings.api_key.delete')

  const onSubmit = () => {
    onDelete()
    onClose()
  }

  return (
    <DatacModal
      isOpened={isOpened}
      onClose={onClose}
      title={intlDelete('title')}
      submitLabel={intlDelete('submit')}
      onSubmit={onSubmit}
      destroyOnClose
    >
      <div className="settings-settings-api-key-delete-modal__body">{intlDelete('description')}</div>
    </DatacModal>
  )
}
